/* Home.css */

.home {
  text-align: center;
}

.home__container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem; 
}

.home__slider {
  width: 100%;
  height: 70vh; /* Adjusted height for mobile */
}

.home__sliderItem {
  height: 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.home__video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.scroll-down {
  position: absolute;
  bottom: 1rem; /* Adjusted bottom for mobile */
  left: 50%;
  transform: translateX(-50%);
  color: white;
  cursor: pointer;
}

.home__row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 -1rem; 
}

.product {
  flex: 1;
  margin: 0 1rem; 
  text-align: center;
}

.product__image {
  width: 100%;
  height: auto;
}

.product__info {
  margin-top: 1rem; 
}

.product__title {
  font-size: 1rem; 
  margin-bottom: 0.5rem; 
}

.product__price {
  font-size: 0.875rem; 
  font-weight: bold;
}

/* Home.css */
.home__flashyBanner {
  background-color: red;
  color: #fff;
  padding: 1rem; 
  text-align: center;
  font-size: 1rem; 
  font-weight: bold;
  animation: flashBanner 1s infinite alternate;
}

@keyframes flashBanner {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.5;
  }
}
