.refund-policy {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.refund-policy h2,
.refund-policy h3 {
  color: #333;
}

.refund-policy p {
  font-size: 16px;
  line-height: 1.6; /* Adjusted line height for better readability */
  color: #555;
}

.refund-policy ol {
  padding-left: 20px;
  font-size: 16px;
  line-height: 1.6; /* Adjusted line height for better readability */
}

.refund-policy ol li {
  color: #555;
}

.refund-policy a {
  color: #007bff;
  text-decoration: none;
  transition: color 0.3s ease; /* Smooth color transition on hover */
}

.refund-policy a:hover {
  text-decoration: underline;
  color: #0056b3; /* Darker blue on hover */
}

@media (max-width: 768px) {
  .refund-policy {
    padding: 15px;
  }

  .refund-policy h2 {
    font-size: 24px; /* Adjusted font size for better mobile display */
  }

  .refund-policy h3 {
    font-size: 20px; /* Adjusted font size for better mobile display */
  }

  .refund-policy p,
  .refund-policy ol {
    font-size: 14px; /* Adjusted font size for better mobile display */
  }
}
