.contact-section {
  text-align: center;
  margin: 1.875rem; /* 3rem converted to rem */
  padding: 3.125rem; /* 5rem converted to rem */
}

.contact-section h2 {
  font-weight: bolder;
  color: black;
  padding: 1.25rem; /* 2rem converted to rem */
  margin-bottom: 0.3125rem; /* 0.625rem converted to rem */
}

.contact-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0.625rem; /* 1rem converted to rem */
  justify-items: center;
  margin-top: 0.9375rem; /* 1.5rem converted to rem */
}

.contact-item {
  display: flex;
  color: royalblue;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.35) 0rem 2rem 6rem;
  padding: 0.9375rem; /* 1.5rem converted to rem */
}

.contact-icon {
  font-size: 1.25rem; /* 2rem converted to rem */
  margin-bottom: 0.3125rem; /* 0.5rem converted to rem */
}

.contact-item a {
  text-decoration: none;
  color: #333;
  transition: color 0.3s;
}

.contact-item a:hover {
  color: #000;
}

@media (max-width: 576px) {
  body {
    width: auto;
    overflow-x: hidden;
  }

  .contact-section h2 {
    font-size: 2.5rem;
    text-align: center;
  }

  .contact-grid {
    padding: 0.9375rem; /* 1.5rem converted to rem */
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    grid-gap: 0.78125rem; /* 1.25rem converted to rem */
  }
}
