/* Orders.css */

.orders {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem; /* Added padding for better spacing */
}

.order {
  border: 1px solid #ccc;
  margin-bottom: 1rem; /* Adjusted margin for better spacing */
  padding: 1rem; /* Adjusted padding for better spacing */
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.order__header {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 0.5rem; /* Adjusted margin for better spacing */
}

.order__info {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0.5rem; /* Adjusted margin for better spacing */
}

.order__info div {
  margin-bottom: 0.25rem; /* Adjusted margin for better spacing */
}

.order__items {
  margin-top: 0.5rem; /* Adjusted margin for better spacing */
}

.order__item {
  display: flex;
  flex-direction: column; /* Adjusted flex direction for better mobile display */
  align-items: center;
  margin-bottom: 0.25rem; /* Adjusted margin for better spacing */
}

.order__item img {
  max-width: 50px;
  max-height: 50px;
  margin: 0.25rem 0; /* Adjusted margin for better spacing */
}

.order__total {
  margin-top: 0.5rem; /* Adjusted margin for better spacing */
  font-size: 1.2rem;
  font-weight: bold;
}
