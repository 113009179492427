/* payment.css */

/* Styles for the Payment component */
.payment {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem; /* Adjusted padding for better spacing */
}

.payment__container {
    width: 100%;
    max-width: 800px;
    background-color: white;
    padding: 2rem; /* Adjusted padding for better spacing */
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.payment__title {
    font-size: 1.5rem; /* Adjusted font size for better readability */
    margin-bottom: 1.5rem; /* Adjusted margin for better spacing */
    border-bottom: 1px solid #ccc;
    padding-bottom: 1rem; /* Adjusted padding for better spacing */
}

.payment__section {
    margin-bottom: 1.5rem; /* Adjusted margin for better spacing */
}

.payment__section h3 {
    font-size: 1.125rem; /* Adjusted font size for better readability */
    margin-bottom: 1rem; /* Adjusted margin for better spacing */
}

.payment__address p {
    margin: 0.5rem 0; /* Adjusted margin for better spacing */
    font-size: 1rem; /* Adjusted font size for better readability */
}

.payment__items {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 1.5rem; /* Adjusted gap for better spacing */
}

.payment__items .checkoutProduct {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem; /* Adjusted padding for better spacing */
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.payment__items .checkoutProduct img {
    max-width: 100%;
    height: auto;
}

.payment__details {
    background-color: white;
    padding: 2rem; /* Adjusted padding for better spacing */
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.payment__details h3 {
    font-size: 1.125rem; /* Adjusted font size for better readability */
    margin-bottom: 1rem; /* Adjusted margin for better spacing */
}

/* Add more styles for your payment form or details here */
