/* Login.css */

.login {
  background-color: white;
  min-height: 100vh; /* Adjusted to min-height for full viewport height */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login__logo {
  margin-top: 2rem; /* Adjusted for better spacing */
  margin-bottom: 2rem; /* Adjusted for better spacing */
  object-fit: contain;
  width: 80px; /* Adjusted logo size */
  margin-right: auto;
  margin-left: auto;
}

.login__container {
  width: 80%; /* Adjusted container width for better responsiveness */
  max-width: 300px; /* Added max-width for larger screens */
  height: fit-content;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: 1px solid lightgray;
  padding: 1.5rem; /* Adjusted padding for better spacing */
}

.login__container > h1 {
  font-weight: 500;
  margin-bottom: 1.5rem; /* Adjusted margin for better spacing */
}

.login__container > form > h5 {
  margin-bottom: 0.5rem; /* Adjusted margin for better spacing */
}

.login__container > form > input {
  height: 2rem; /* Adjusted input height for better consistency */
  margin-bottom: 1rem; /* Adjusted margin for better spacing */
  background-color: white;
  width: 100%;
}

.login__container > p {
  margin-top: 1.5rem; /* Adjusted margin for better spacing */
  font-size: 1rem; /* Adjusted font size for better readability */
}

.login__signInButton,
.login__registerButton {
  border-radius: 2px;
  width: 100%;
  height: 2rem; /* Adjusted button height for better consistency */
  margin-top: 1rem; /* Adjusted margin for better spacing */
}

.login__signInButton {
  background: #f0c14b;
  border: 1px solid;
  border-color: #a88734 #9c7e31 #846a29;
}

.login__registerButton {
  border: 1px solid darkgray;
}
