.bg-image {
  position: relative;
  overflow: hidden;
  height: 300px; /* Set a fixed height for the cards */
  transition: transform 0.3s ease-in-out;
}

.bg-image:hover {
  transform: scale(1.1); /* Magnify the card on hover */
}

.bg-image img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hover-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.mask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
}

.mask h5 {
  margin: 1rem;
}

@media (max-width: 768px) {
  .bg-image {
    height: 200px; /* Adjusted height for better mobile display */
  }

  .mask h5 {
    font-size: 1rem; /* Adjusted font size for better mobile display */
    margin: 0.5rem; /* Adjusted margin for better spacing */
  }
}
