.product {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  background-color: white;
  z-index: 1;
  flex: 1 1 calc(50% - 20px);
  margin: 10px;
  text-align: center;
  padding-bottom: 10px;
}

.product > img {
  max-height: 200px;
  width: 100%;
  object-fit: contain;
  margin-bottom: 15px;
  transition: transform 0.3s ease;
}

.product > img:hover {
  transform: scale(1.1);
}

.product__button {
  background-color: white;
  border: 1px solid #f89c36;
  padding: 1.5em; /* Adjusted padding for better mobile display */
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #f89c36;
  cursor: pointer;
  transition: all 0.3s ease;
}

.product__button:hover {
  background-color: #f89c36;
  border: 1px solid white;
  color: white;
}

.product__info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100px;
  margin-bottom: 15px;
  text-align: center;
}

.product__info > p {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 5px;
}

.product__price {
  font-size: 1.2em;
  margin-top: 5px;
}

@media (max-width: 768px) {
  .product {
    flex: 1 1 calc(100% - 20px); /* Adjusted flex property for full width on smaller screens */
  }
}
