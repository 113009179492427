/* About.css */

.about-container {
  text-align: center;
  padding: 1.25rem; /* 20px converted to rem */
}

.about-content {
  padding: 1.25rem; /* 20px converted to rem */
}

.about-text {
  font-size: 1rem; /* 16px converted to rem */
  line-height: 1.6;
  color: #131921;
}

.about-image {
  border-radius: 0.5rem; /* 8px converted to rem */
  margin-top: 1.25rem; /* 20px converted to rem */
}
